<template>
  <div class="wrap">
    <i class="borderIcon iconTopLeft"></i>
    <i class="borderIcon iconBottomLeft"></i>
    <i class="borderIcon iconTopRight"></i>
    <i class="borderIcon iconBottomRight"></i>

    <iframe v-if="moduleData.type==='url'"
      class="iframeClass"
      :src="moduleData.content.includes('hide_title=1') ? moduleData.content: moduleData.content+'?hide_title=1'"
      frameborder="0"
      width="100%"
      height="100%"
      scrolling="auto"
    ></iframe>
    <div class="title" v-if="moduleData.type==='title'">
      <span :style="{fontSize:moduleData.size,color:moduleData.color}">{{moduleData.content}}</span>
    </div>

  </div>
</template>
<script>
export default {
  name: 'module',
  props: {
    name: {
      type: String,
      default: ''
    },
    moduleData: {
      type: Object,
      default: () => {}
    }
  },
  created() {},
  data() {
    return {
      // moduleData: {},
    }
  },
  methods: {

  }
}
</script>
<style  lang="scss" scope>
.wrap {
  position: relative;
  // border: 1px solid #384e74;
  // background-color: rgba(37, 37, 46, 0.5);
  background-color: #fff;
  height: 100%;
  width: 100%;
  padding:5px;
  box-sizing: border-box; //固定大小
  box-shadow: 0px 5px 15px RGB(0 255 235 / 30%);
  .borderIcon {
    width: 15px;
    height: 15px;
    border: 2px solid #4687f8;
    position: absolute;
  }
  .iconTopLeft {
    border-right: none;
    border-bottom: none;
    left: 0;
    top: 0;
  }
  .iconBottomLeft {
    border-top: none;
    border-right: none;
    left: 0;
    bottom: 0;
  }
  .iconTopRight {
    border-left: none;
    border-bottom: none;
    right: 0;
    top: 0;
  }
  .iconBottomRight {
    border-top: none;
    border-left: none;
    right: 0;
    bottom: 0;
  }
  .title {
      position: absolute;
      left: 50%;
      top:50%;
      transform: translate(-50%, -50%);
  }
}
.iframeClass {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}
.text {
  width: 100%;
  text-align: center;
}
</style>
