<template lang="">
  <div>
    <!-- <el-button style="padding-left: 30px;" @click="openSystemConf" size="small" type="text" icon="el-icon-setting">常用系统设置</el-button> -->
    <div class="menuList">
      <ul>
        <li v-for="(item,index) of showSystemList"
        :key="index"
        @click="goSystem(item)"
        class="animate__animated animate__fadeInLeft"
        >{{item.usName}}</li>
      </ul>
    </div>
    <!-- 常用系统配置弹窗 -->
    <system-conf-dialog v-bind.sync="confObj" @submitSystemConf="submitSystemConf" @closeDialog="closeDialog"></system-conf-dialog>
  </div>
</template>
<script>
import { userCenterUserSystemMapNew } from '@/api/system-list'
import { bussinessTreeNew } from '@/api/menu-manage'
import Cookies from 'js-cookie'
import { setSystemToken } from '@/utils/systemid'
import { mapGetters } from 'vuex'
import systemConfDialog from './systemConfDialog.vue'

export default {
  components: { systemConfDialog },
  name: 'systemMenu',
  data() {
    return {
      confObj: {
        confVisible: false,
        showSystemList: [],
        hiddenSystem: []
      },
      showSystemList: [],
      hiddenSystem: []
    }
  },
  computed: {
    ...mapGetters(['token', 'roles'])
  },
  created() {
    this.getSystemList()
  },
  methods: {
    getSystemList() {
      userCenterUserSystemMapNew()
        .then((response) => {
          const allSystemList = response.data.overallSystem || []
          this.hiddenSystem = response.data.hiddenSystem || []
          if (this.hiddenSystem.length > 0) {
            const hiddenIds = this.hiddenSystem.map(item => item.usId)
            this.showSystemList = allSystemList.filter(item => !hiddenIds.includes(item.usId))
          } else {
            this.showSystemList = allSystemList
          }

          this.showSystemList.forEach((v) => {
            if (Number(this.$route.query.usId) === v.usId) {
              this.goSystem(v)
            }
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    goSystem(item) {
      console.log(item)
      // 在跳转前加一个请求，以防用户长时间停留在这个页面导致token过期了还跳转至其它系统
      bussinessTreeNew(item.usId).then(() => {
        // 权限系统 跳转其它系统后cookie里面的systemid会被覆盖
        // 现解决方法：权限系统1，新权限系统测试243，新权限系统本地245
        if (item.usId === Number(process.env.VUE_APP_SYSTEM_ID_SAVE)) {
          setSystemToken(item.usId)
        }
        this.$store.dispatch('GenerateRoutes', {}).then(() => {
          // 根据roles权限生成可访问的路由表
          this.$router.addRoutes(this.$store.getters.addRouters) // 动态添加可访问路由表
          let object = {
            userId: this.roles.userInfo.userId,
            systemCode: item.usId,
            username: this.roles.userInfo.username,
            ip: Cookies.get('userIp'),
            token: this.token
            // userInfo: this.roles.userInfo,
            // originUrl: window.location.href （后端校验token不通过，后期后端做处理后，在子系统可用传递的URL做回退，先写死）
          }
          // 只有权限系统加上这个 其它系统不需要
          if (item.usId === Number(process.env.VUE_APP_SYSTEM_ID_SAVE)) {
            object.userInfo = JSON.stringify(this.roles.userInfo)
          }
          object = JSON.stringify(object)
          const Base64 = require('js-base64').Base64
          const urlCode = Base64.encode(object)
          //   window.open(`http://${item.usUrl}?code=${urlCode}`);
          var browser = {
            versions: (function() {
              var u = navigator.userAgent
              return {
                // 移动终端浏览器版本信息
                trident: u.indexOf('Trident') > -1, // IE内核
                presto: u.indexOf('Presto') > -1, // opera内核
                webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
                gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, // 火狐内核
                mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
                ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
                android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android终端或uc浏览器
                iPhone: u.indexOf('iPhone') > -1, // 是否为iPhone或者QQHD浏览器
                iPad: u.indexOf('iPad') > -1, // 是否iPad
                webApp: u.indexOf('Safari') === -1 // 是否web应该程序，没有头部与底部
              }
            })(),
            language: (
              navigator.browserLanguage || navigator.language
            ).toLowerCase()
          }
          if (browser.versions.mobile || browser.versions.iPad) {
            // 判断是否是移动设备打开。browser代码在下面
            /* var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
            if (ua.match(/MicroMessenger/i) === "micromessenger") {
            //在微信中打开
            setInterval(WeixinJSBridge.call('closeWindow'),2000);
            }
            if (ua.match(/WeiBo/i) === "weibo") {
            //在新浪微博客户端打开
            }
            if (ua.match(/QQ/i) === "qq") {
            //在QQ空间打开
            }
            if (browser.versions.ios) {
            //是否在IOS浏览器打开
            }
            if(browser.versions.android){
            //是否在安卓浏览器打开
            }*/
            // window.location.href = `http://${item.usUrl}?code=${urlCode}`
            window.location.href = `http://${item.usUrl}?code=${urlCode}`
            if (item.usUrl.indexOf('http') > -1) {
              window.location.href = `${item.usUrl}?code=${urlCode}`
            } else {
              window.location.href = `http://${item.usUrl}?code=${urlCode}`
            }
          } else {
            // window.open(`http://${item.usUrl}?code=${urlCode}`)
            if (item.usUrl.indexOf('http') > -1) {
              // url 对: //不转义 丢失
              const decode = decodeURIComponent(item.usUrl)
              window.open(`${decode}?code=${urlCode}`)
            } else {
              window.open(`http://${item.usUrl}?code=${urlCode}`)
            }
          }
        })
      })
    },
    openSystemConf() {
      this.confObj = {
        confVisible: true,
        hiddenSystem: this.hiddenSystem,
        showSystemList: this.showSystemList
      }
    },
    closeDialog() {
      this.confObj.confVisible = false
    },
    submitSystemConf(showSystemList, hiddenSystem) {
      this.showSystemList = JSON.parse(JSON.stringify(showSystemList))
      this.hiddenSystem = JSON.parse(JSON.stringify(hiddenSystem))
    }
  }
}
</script>
<style scope lang="scss">
.menuList {
  position: absolute;
  width: 240px;
  height: calc(100vh - 70px);
  border: 1px solid #444444;
  box-shadow: 0px 5px 5px rgb(78, 68, 105);
  margin-left: 10px;
  overflow-y: auto;
  left: 0;
  // overflow: scroll;
  ul {
    list-style: none;
    padding:0 20px;
    li {
      color: #c5ccff;
      line-height: 30px;
      cursor: pointer;
      &:hover {
        text-shadow: 0px 0px 2px #409EFF;
        // animation: liLight 1s;
        color: #409EFF;
      }
    }
  }
}
@keyframes mymove {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes liLight {
  10% {
    color: rgb(11, 234, 235);
  }
  50% {
    color: rgb(220, 235, 11);
  }
  100% {
    color: rgb(75, 235, 11);
  }
}
</style>
