<template>
  <el-dialog class="conf-dailog" :visible.sync="confVisible" :close-on-click-modal="false" :before-close="closeDialog" title="常用系统配置"
    width="1000px" v-ismove>
    <div style="max-height: 600px;overflow-y: auto;">
      <p>已选系统</p>
      <div v-if="is_selectList.length < 1" style="text-align: center;">
        <span style="color: #d0d0d0;font-size: 14px;">暂未选择需要显示的系统</span>
      </div>
      <el-card
        v-else
        class="box-card remove_card"
        v-for="(item, index) in is_selectList"
        :key="item.usId"
        shadow="hover"
        @click.native="removeClick(index)">
        <!-- <div class="head_img_txt">
          <svg-icon icon-class="system-icon"
          :style="{color:'rgb(165, 220, 225)'}"
          ></svg-icon>
        </div> -->
        <div class="name_txt">
          <!-- <svg-icon icon-class="system-icon"
          :style="{color:'rgb('+Math.floor(Math.random() * 256)+','+Math.floor(Math.random() * 256)+','+Math.floor(Math.random() * 256)+')'}"
          ></svg-icon> -->
          <svg-icon icon-class="system-icon"
          style="color:#409EFF;vertical-align: bottom;"
          ></svg-icon>
          <span :title="item.usName">{{ item.usName }}</span>
        </div>
        <i class="el-icon-remove icon_btn remove_btn"></i>
      </el-card>
      <p>可选系统</p>
      <div v-if="no_selectList.length < 1" style="text-align: center;">
        <span style="color: #d0d0d0;font-size: 14px;">暂无可选系统</span>
        <!-- <el-empty description="可选的系统都已全部选中"></el-empty> -->
      </div>
      <el-card
        v-else
        class="box-card add_card"
        v-for="(item,i) in no_selectList"
        :key="item.usId"
        @click.native="addClick(i)">
        <!-- <div class="head_img_txt">
          <svg-icon icon-class="system-icon"
          :style="{color:'rgb(165, 220, 225)'}"
          ></svg-icon>
        </div> -->
        <div class="name_txt">
          <svg-icon icon-class="system-icon"
          style="color:#67C23A;vertical-align: bottom;"
          ></svg-icon>
          <!-- <svg-icon icon-class="system-icon"
          :style="{color:'rgb('+Math.floor(Math.random() * 256)+','+Math.floor(Math.random() * 256)+','+Math.floor(Math.random() * 256)+')'}"
          ></svg-icon> -->
          <span :title="item.usName">{{ item.usName }}</span>
        </div>
        <i class="el-icon-circle-plus icon_btn add_btn"></i>
      </el-card>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="closeDialog()">取消</el-button>
      <el-button type="primary" size="small" @click="submit()">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { hideUserSystem } from '@/api/system-list'

export default {
  name: 'systemConfDialog',
  props: {
    confVisible: {
      type: Boolean,
      defaults: false
    },
    showSystemList: {
      type: Array,
      defaults: []
    },
    hiddenSystem: {
      type: Array,
      defaults: []
    }
  },
  data() {
    return {
      no_selectList: [],
      is_selectList: []
    }
  },
  watch: {
    confVisible(val) {
      this.is_selectList = JSON.parse(JSON.stringify(this.showSystemList))
      this.no_selectList = JSON.parse(JSON.stringify(this.hiddenSystem))
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
      this.no_selectList = []
      this.is_selectList = []
    },
    removeClick(index) {
      this.no_selectList.unshift(this.is_selectList[index])
      this.is_selectList.splice(index, 1)
    },
    addClick(index) {
      this.is_selectList.unshift(this.no_selectList[index])
      this.no_selectList.splice(index, 1)
    },
    submit() {
      hideUserSystem({
        usId: this.no_selectList.length > 0 ? this.no_selectList.map(item => item.usId) : [],
        userId: this.$store.getters.roles.userInfo.userId
      }).then(response => {
        if (response.code === '000000') {
          this.$emit('submitSystemConf', this.is_selectList, this.no_selectList)
          this.closeDialog()
        }
      })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang='scss' scoped>
.conf-dailog {
  /deep/ .el-card__body {
    padding: 10px;

    // text-align: -webkit-center;
  }
  .box-card {
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;

    // position: relative;
    // text-align: center;
  }
  p{
    font-size: 16px;
    font-weight: 600;
  }
}
.remove_card :hover {
  // background: rgb(254, 240, 240);
  // border-color: #b2e39b;
}
.add_card :hover {
  // background: rgb(240, 249, 235);
  // border-color: #b2e39b;
}
.name_txt {
  display: inline-block;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // display: inline-flex;
  // align-items: center;
  vertical-align: middle;
}
.icon_btn {
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle

  // position: absolute;
  // top: 0px;
  // right: 0px;
}
.remove_btn {
  color: #c4dffb;
}
.add_btn {
  color: #b2e39b;
}
.head_img_txt {
  line-height: 30px;
  font-size: 18px;
}
</style>
