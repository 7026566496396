// 系统列表
import request from '@/utils/request'

// userCenter页专用接口，通过用户ID查询用户所拥有的系统
export function userCenterUserSystemMap() {
  return request({
    url: `/userSystemMap/get/userId/forlogin`,
    method: 'get'
    // params: param
  })
}
// userCenter页专用接口，通过用户ID查询用户所拥有的系统
export function userCenterUserSystemMapNew() {
  return request({
    url: `/userSystemMap/get/userId/customForLogin`,
    method: 'get'
    // params: param
  })
}

// 通过用户ID查询用户所拥有的系统
export function userSystemMap(id) {
  let url
  if (id) {
    url = `/userSystemMap/get/userId/${id}`
  } else {
    url = `/userSystemMap/get/userId`
  }
  return request({
    url: url,
    method: 'get'
    // params: param
  })
}
// 通过用户id获取当前用户所有系统，并查看用户选中哪些系统
export function userSystemMapSelected(userId) {
  return request({
    url: `/userSystemMap/get/${userId}`,
    method: 'get'
    // params: param
  })
}

export function userSystemAll(param) {
  return request({
    url: '/userSystem/get/all',
    method: 'get'
    // params: param
  })
}

// 设置隐藏的系统列表
export function hideUserSystem(data) {
  return request({
    url: '/userSystemMap/editCover',
    method: 'post',
    data: data
  })
}
