<template lang="">
  <div class="userCenter">
    <!-- 头部 -->
    <div class="hearder">
      <div class="lineOne"></div>
      <div class="lineTwo">
        <div class="lightLineBottom"></div>
        <div class="lightLineBottom2"></div>
        <div class="lightLineRight">
        <div class="lightLineRight2"></div>
      </div>
        <div class="title">Dashboard</div>
      </div>
      <div class="lightLineLeft">
         <div class="lightLineLeft2"></div>
      </div>

      <div class="lineThree"></div>
      <div class="lineFour"></div>

      <div class="rightShow">
        <span class="dateClass">{{date.toLocaleString()}}</span>
        <screenfull class="right-menu-item hover-effect" @changeFull="changeFull" />
        <span class="nameClass">{{$store.getters.name}}</span>
        <el-button @click="$router.push('/resetPassword')" type="text" size="small">修改密码</el-button>
        <el-button type="text" @click="onExit" size="small">退出</el-button>
      </div>
    </div>
    <!-- 头部 -->
    <!-- 收缩按钮 -->
    <div class="logo">
        <img src="../../assets/sfclogo.png" />
      </div>
     <div :class="openStatus ? 'setpackup':'setpackup-move'" title="常用系统设置" style="cursor:pointer">
        <i class="el-icon-setting" @click="openConfDialog"></i>
      </div>
     <div :class="openStatus ? 'packup':'packup packup-move'" :title="openStatus ? '收起':'展开'" style="cursor:pointer">
        <!-- <el-button @click="openSystemConf" size="small" type="text" icon="el-icon-setting"></el-button> -->
        <!-- <i class="el-icon-setting" style="margin-right:5px;" @click="openConfDialog"></i> -->
        <i class="el-icon-s-fold" @click="toggleOpen"></i>
      </div>
       <!-- 收缩按钮 -->
       <!-- 系统菜单 -->
    <systemMenu ref="systemMenu" :class="openStatus ? '':'animate__animated animate__fadeOutTopLeft'  " />
    <!-- 系统菜单 -->
    <!-- 模块内容 -->
    <div :class=" openStatus ? 'content-rihgt' :'content-rihgt content-rihgt-move'">
      <div v-if="moduleA.width" class="animate__animated animate__bounceInDown moduleA" :style="moduleA.style">
        <module :moduleData="moduleA" :name="'A'" />
      </div>
      <div v-if="moduleB.width" class="animate__animated animate__bounceInLeft moduleB"  :style="moduleB.style">
        <module :moduleData="moduleB" :name="'B'" />
      </div>
      <div v-if="moduleC.width" class="animate__animated animate__bounceInRight moduleC" :style="moduleC.style">
        <module :moduleData="moduleC" :name="'C'" />
      </div>
      <div v-if="moduleD.width" class="animate__animated animate__bounceInRight moduleD" :style="moduleD.style">
        <module :moduleData="moduleD" :name="'D'" />
      </div>

    </div>
    <!-- </div> -->
    <!-- 模块内容 -->
  </div>
</template>
<script>
import module from './components/module.vue'
import systemMenu from './components/systemMenu.vue'
import { getDepartTree } from '@/api/role.js'
import { getStyle } from '@/api/style.js'
import Screenfull from '@/components/Screenfull'
import { parseTime } from '@/utils/index'

export default {
  components: { module, systemMenu, Screenfull },
  data() {
    return {
      openStatus: true, // 打开或收起左侧菜单
      systemList: [],
      timer: '',
      date: parseTime(new Date()),
      type: '', // 浏览器类型 退出时判断跳转地址
      moduleA: {},
      moduleB: {},
      moduleC: {},
      moduleD: {}
    }
  },
  created() {
    this.checkBrowser()
    this.getDepartId()
  },
  mounted() {
    this.getTime()
  },

  watch: {
    moduleA(val) {
      this.$set(this.moduleA, 'style', {
        width: val.width + '%',
        height: val.height + '%'
      })
    },
    moduleB(val) {
      if (this.moduleA.width < 100) {
        this.$set(this.moduleB, 'style', {
          width: val.width + '%',
          height: val.height + '%',
          left: this.moduleA.width + '%',
          top: 0
        })
      } else {
        this.$set(this.moduleB, 'style', {
          width: val.width + '%',
          height: val.height + '%',
          left: 0,
          top: this.moduleA.height + '%'
        })
      }
    },
    moduleC(val) {
      // 计算 1+3的高度 =100 可能 1 宽100   2+3 宽100 ； 1+3 高100 2高100
      if (
        this.moduleA.height + val.height === 100 &&
        this.moduleA.width + this.moduleB.width <= 100
      ) {
        this.$set(this.moduleC, 'style', {
          width: val.width + '%',
          height: val.height + '%',
          left: 0,
          top: this.moduleA.height + '%'
        })
      } else if (
        this.moduleA.height + val.height === 100 &&
        this.moduleA.width + this.moduleB.width > 100
      ) {
        this.$set(this.moduleC, 'style', {
          width: val.width + '%',
          height: val.height + '%',
          left: this.moduleB.width + '%',
          top: this.moduleA.height + '%'
        })
      } else {
        this.$set(this.moduleC, 'style', {
          width: val.width + '%',
          height: val.height + '%',
          left: this.moduleA.width + '%',
          top: this.moduleB.height + '%'
        })
      }
    },
    moduleD(val) {
      if (
        this.moduleC.width < 100 &&
        this.moduleC.width > 0 &&
        this.moduleB.height
      ) {
        this.$set(this.moduleD, 'style', {
          width: val.width + '%',
          height: val.height + '%',
          left: this.moduleC.width + '%',
          top: this.moduleB.height + '%'
        })
      } else if (this.moduleC.width > 0 && this.moduleB.height === 0) {
        this.$set(this.moduleD, 'style', {
          width: val.width + '%',
          height: val.height + '%',
          left: this.moduleC.width + '%',
          top: this.moduleA.height + '%'
        })
      } else {
        this.$set(this.moduleD, 'style', {
          width: val.width + '%',
          height: val.height + '%',
          left: this.moduleA.width + '%',
          top: this.moduleB.height + '%'
        })
      }
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer) // 在Vue实例销毁前，清除时间的定时器
    }
  },
  methods: {
    toggleOpen() {
      this.openStatus = !this.openStatus
    },
    getTime() {
      const _this = this // 声明一个变量指向Vue实例this，保证作用域一致
      this.timer = setInterval(() => {
        _this.date = parseTime(new Date()) // 修改数据date
      }, 1000)
    },
    onExit() {
      this.$store.dispatch('LogOut').then(() => {
        if (this.type === 'PC') {
          location.reload()
        } else if (this.type === 'mobile') {
          window.location.href = `http://account.suntekcorps.com:8080` // 生产
          // window.location.href=`http://190.168.1.50:8060/`; // 测试
          // window.location.href=`http://localhost:8089/`; // 本地测试
        }
        // location.reload(); // 为了重新实例化vue-router对象 避免bug
      })
    },
    // 判断当前浏览器类型
    checkBrowser() {
      var browser = {
        versions: (function() {
          var u = navigator.userAgent
          return {
            // 移动终端浏览器版本信息
            trident: u.indexOf('Trident') > -1, // IE内核
            presto: u.indexOf('Presto') > -1, // opera内核
            webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, // 火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android终端或uc浏览器
            iPhone: u.indexOf('iPhone') > -1, // 是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1, // 是否iPad
            webApp: u.indexOf('Safari') === -1 // 是否web应该程序，没有头部与底部
          }
        })(),
        language: (
          navigator.browserLanguage || navigator.language
        ).toLowerCase()
      }
      if (browser.versions.mobile) {
        this.type = 'mobile'
      } else {
        this.type = 'PC'
      }
    },
    getDepartId() {
      getDepartTree().then((res) => {
        // const departmentId = this.$store.getters.roles.userInfo.departmentId
        const data = res.data
        if (data.length > 0) {
          // 查询 用户 树形部门 通过多个部门 如果风格都设置了 取最接近部门的风格
          const newLevel = this.getParent(
            data,
            this.$store.getters.roles.userInfo.departmentId,
            this.$store.getters.roles.userInfo.userId
          )
          // newLevel.push(this.$store.getters.roles.userInfo.userId)
          const params = {
            // saveId: newLevel.toString(),
            userId: newLevel
          }
          getStyle(params)
            .then((res) => {
              this.setStyle(res.data[0])
            })
            .catch(() => {})
        }
      })
    },
    setStyle(data) {
      this.moduleA = {
        // color: data.partAcolor,
        content: data.partAcontent,
        type: data.partAtype,
        color: data.partAcolor,
        size: data.partAsize,
        width: data.partAw,
        height: data.partAh,
        style: {}
      }
      this.moduleB = {
        // color: data.partBcolor,
        content: data.partBcontent,
        type: data.partBtype,
        color: data.partBcolor,
        size: data.partBsize,
        width: data.partBw,
        height: data.partBh,
        style: {}
      }
      this.moduleC = {
        // color: data.partCcolor,
        content: data.partCcontent,
        type: data.partCtype,
        color: data.partCcolor,
        size: data.partCsize,
        width: data.partCw,
        height: data.partCh,
        style: {}
      }
      this.moduleD = {
        // color: data.partDcolor,
        content: data.partDcontent,
        type: data.partDtype,
        color: data.partDcolor,
        size: data.partDsize,
        width: data.partDw,
        height: data.partDh,
        style: {}
      }
    },
    getParent(data, id, userId) {
      let idStr = ''
      function find(data, id) {
        data.forEach(item => {
          if (item.udId === id) {
            idStr = item.longId
          } else if (item.children) {
            find(item.children, id)
          }
        })
      }
      find(data, id)
      const ids = idStr.split('-')
      ids.push(userId)
      return ids.join(',')
    },
    changeFull(val) {
      this.openStatus = val
    },
    openConfDialog() {
      this.$refs.systemMenu.openSystemConf()
    }
  }
}
</script>
<style scope lang="scss">
.userCenter {
  position: relative;
  // background-color: rgb(11, 18, 46);
  background-color: rgb(37, 37, 46);
  // background-image: url('./50.gif');
  height: calc(100vh);
  width: 100%;
}
.content-rihgt {
  position: absolute;
  width: calc(100% - 260px);
  height: calc(100vh - 60px); //减去顶部的距离
  left: 260px;
  box-sizing: border-box;

  // display: flex;
  // flex-wrap:wrap ;
  > div {
    position: absolute;
    color: #fff;
    box-sizing: border-box;
    padding-bottom: 10px;
    padding-right: 10px;
    left: 0;
    top: 0;
  }
}
.hearder {
  position: relative;
  height: 60px;
  // margin-bottom: 10px;
  .lineOne {
    width: calc(100% - 10px);
    margin: 0 5px;
    height: 20px;
    border-bottom: 1px solid #717a88;
    box-shadow: 0px 3px 3px rgb(78, 68, 105);
  }
  .lineTwo {
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translate(-50%, 0%);
    height: 30px;
    width: 400px;
    border-bottom: 2px solid rgb(78, 68, 105);
    background-color: rgb(37, 37, 46);
    z-index: 4;
    .title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -10px);
      color: #fff;
      width: 350px;
      height: 35px;
      text-align: center;
      // letter-spacing: 8px;
    }
  }
  .lightLineLeft {
    position: absolute;
    left: 50%;
    top: -206px;
    transform: rotate(45deg) translate(0, 302px);
    height: 26px;
    width: 27px;
    background-color: rgb(37, 37, 46);
    // background-color: rgb(55, 55, 212);
    border-bottom: 1px solid rgb(78, 68, 105);
    z-index: 2;
  }
  .lightLineLeft2 {
    position: absolute;
    left: 0px;
    top: 25px;
    transform-origin: left top;
    transform: rotate(0deg) translate(0px, 0px);
    height: 1px;
    width: 0px;
    background-color: #c1daf4;
    animation: leftMtmove 4s linear 1 forwards;
  }
  .lightLineRight {
    position: absolute;
    right: -13px;
    top: -3px;
    transform: rotate(-45deg) translate(0px, 0px);
    height: 26px;
    width: 27px;
    background-color: rgb(37, 37, 46);
    // background-color: #fff;
    border-bottom: 1px solid rgb(78, 68, 105);
    z-index: 2;
  }
  .lightLineRight2 {
    position: absolute;
    right: -1px;
    top: 26px;
    transform-origin: left bottom;
    // transform: rotateX(-180deg);
    transform: rotateX(-180deg);
    height: 1px;
    width: 0px;
    background-color: #c1daf4;
    animation: rightMtmove 4s linear 1 forwards;
  }
  .lightLineBottom {
    position: absolute;
    left: 0px;
    top: 27px;
    height: 1px;
    width: 0px;
    background-color: #c1daf4;
    box-shadow: 0px 0px 5px 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF,
      0 0 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF;
    animation: bottomtmove 6s linear 1 forwards;
    z-index: 4;
  }
  .lightLineBottom2 {
    position: absolute;
    right: -2px;
    top: 27px;
    height: 1px;
    width: 0px;
    background-color: #c1daf4;
    box-shadow: 0px 0px 5px 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF,
      0 0 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF;
    animation: bottomtmove 6s linear 1 forwards;
    z-index: 4;
  }
  .lineThree {
    //左边顶点出发发光
    position: absolute;
    left: 5px;
    top: 20px;
    width: 0px;
    height: 1px;
    background-color: #c1daf4;
    box-shadow: 0px 0px 5px 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF,
      0 0 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF;
    animation: leftmove 3s linear 1 forwards;
    z-index: 1;
  }
  .lineFour {
    //右边发光线
    position: absolute;
    right: 5px;
    top: 20px;
    width: 0px;
    height: 1px;
    // transform: translate(200px, 0px);
    background-color: #c1daf4;
    box-shadow: 0px 0px 5px 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF,
      0 0 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF;
    animation: rightmove 3s linear 1 forwards;
  }
}
@keyframes leftmove {
  from {
    left: 5px;
    z-index: 1;
  }
  to {
    width: calc(50% - 217px);
    z-index: 1;
  }
}
@keyframes rightmove {
  0% {
    width: 0px;
  }
  100% {
    width: calc(50% - 200px);
  }
}
@keyframes leftMtmove {
  //中间左边
  75% {
    width: 0px;
    box-shadow: 0px 0px 10px 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF,
      0 0 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF;
  }
  100% {
    width: 26px;
    box-shadow: 0px 0px 10px 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF,
      0 0 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF;
  }
}
@keyframes rightMtmove {
  //中间有点斜线
  76% {
    width: 0px;
    box-shadow: 0px 0px 10px 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF,
      0 0 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF;
  }
  100% {
    width: 26px;
    box-shadow: 0px 0px 10px 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF,
      0 0 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF;
  }
}
@keyframes bottomtmove {
  //中间左边
  67% {
    width: 0px;
    box-shadow: 0px 0px 10px 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF,
      0 0 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF;
  }
  100% {
    width: 201px;
    box-shadow: 0px 0px 10px 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF,
      0 0 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF, 0 0 1px #409EFF;
  }
}
.setpackup {
  color: #fff;
  position: absolute;
  left: 209px;
  top: 37px;
  font-size: 18px;
  z-index: 2;
}
.setpackup-move {
  display: none;
}
.packup {
  //收起
  color: #fff;
  position: absolute;
  left: 232px;
  top: 37px;
  font-size: 18px;
  z-index: 2;
}
.packup-move {
  left: 98px;
}
.content-rihgt-move {
  width: 100%;
  left: 0px;
}

.fullIcon {
  position: absolute;
  right: 127px;
  top: 35px;
}
.right-menu-item {
  display: inline-block;
  padding: 0 8px;
  height: 100%;
  font-size: 14px;
  color: #dde0e6;
  vertical-align: text-bottom;

  &.hover-effect {
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }
}
.logo{
  position: absolute;
  left:0px;
  top:8px;
  width: 90px;
  height:50px;
  // padding-right:3px;
  z-index: 99;
  // padding: 0 10px;
  background-color: rgb(37, 37, 46);
  >img{
    width: 100%;
  }
}
.rightShow{
  color:#fff;
  position: absolute;
  right:20px;
  top:30px;
  vertical-align:bottom;
  >span{
    margin: 5px;
  }
  .dateClass{
    position: relative;
    top:2px;
  }

}

</style>
